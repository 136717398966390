import React from "react";
import { Button } from "@material-ui/core";
import "./custom-button.scss";

type Color = "inherit" | "primary" | "secondary" | "default" | undefined;
type Variant = "text" | "outlined" | "contained" | undefined;

interface IProps {
  text: string | JSX.Element;
  color: Color;
  variant: Variant;
  component?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  type?: "button" | "reset" | "submit";
  onClick?: () => void;
}

export const CustomButton: React.FC<IProps> = ({ text, ...restProps }) => (
  <Button className="custom-button" {...restProps}>
    {text}
  </Button>
);
