import React from 'react';
import { Box, Container, IconButton, Typography } from '@material-ui/core';
import { CustomButton } from '../../atoms/CustomButton/CustomButton';
import { useModal } from './useModal';
import errorIcon from '../../assets/images/email-error.svg';
import './modal.scss';
import { Close } from '@material-ui/icons';

interface IProps {
	closeModal: () => void;
	courseLink: string;
}

export const Modal: React.FC<IProps> = ({ closeModal, courseLink }) => {
	const { handleSubmit, handleEmailChange, userEmail, isError } = useModal(closeModal, courseLink);

	return(
		<Box display="flex" justifyContent="center" alignItems="center" className="modal-overlay">
			<Container fixed style={{ display: "flex", justifyContent: "center" }}>
				<form className="modal" onSubmit={handleSubmit} noValidate>
					<IconButton className="modal__close-btn" onClick={closeModal}>
						<Close />
					</IconButton>
					<Typography component="h6" variant="h6" color="primary">Внимание!</Typography>
					<Typography component="p" variant="body1" color="textPrimary">
						Чтобы произвести оплату и получить ссылку на курс, введите адрес электронной почты:
					</Typography>
					<Box display="flex" className="controls">
						<input value={userEmail} 
										type="email" 
										name="user_email" 
										id="email" 
										placeholder="Введите адрес электронной почты"
										className={isError ? "email-error" : ""}
										onChange={handleEmailChange} />
						<CustomButton color="secondary" variant="contained" type="submit" text="Подтвердить" />
					</Box>
					{isError && (
						<div className="error">
							<img src={errorIcon} alt=""/>
							<small>Попробуйте email вида "mail@gmail.com"</small>
						</div> 
					)} 
				</form>
			</Container>			
		</Box>
	);
}
