import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { MassageCard } from "../MassageCard/MassageCard";
import { MassageCardMobile } from "../MassageCardMobile/MassageCardMobile";

import "./massage-section.scss";
import { useEffect, useState } from "react";
import { getServices } from "../../services";
import i18n from "../../i18n";
import { Loader } from "../Loader/Loader";

interface ServiceItem {
  titleRu: string;
  title_uk: string;
  infoRu: string;
  infoUk: string;
  image: {
    url: string;
  };
  id: string;
}

interface ServiceList {
  servicesApi: ServiceItem[];
}

export const MassageSection = () => {
  const [servicesList, setServicesList] = useState<ServiceList>();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchPrices = async () => {
    try {
      const res = await getServices();
      const servicesListData = res as ServiceList;
      setServicesList(servicesListData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    waitForAnimate: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <Container component="section" className="massage" id="massage" fixed>
      <Box
        display="flex"
        flexDirection="column"
        className="massage__wrapper"
        data-aos="zoom-in-down"
      >
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="massage__title"
        >
          {t("massage_section_title")}
        </Typography>
        {isLoading ? (
          <div className="loader__wrapper">
            <Loader fixed={false} />
          </div>
        ) : isSm ? (
          servicesList?.servicesApi.map(
            (
              item: {
                titleRu: string;
                title_uk: string;
                infoUk: string;
                infoRu: string;
                image: {
                  url: string;
                };
              },
              index: number
            ) => (
              <MassageCardMobile
                key={index}
                title={i18n.language === "ru" || i18n.language === "ru-RU" ? item.titleRu : item.title_uk}
                text={i18n.language === "ru" || i18n.language === "ru-RU" ? item.infoRu : item.infoUk}
                id={index}
                img={item.image?.url}
              />
            )
          )
        ) : (
          <Slider {...settings} className="massage__slider">
            {servicesList?.servicesApi.map((item: ServiceItem) => (
              <MassageCard
                key={item.id}
                title={i18n.language === "ru" || i18n.language === "ru-RU" ? item.titleRu : item.title_uk}
                text={i18n.language === "ru" || i18n.language === "ru-RU" ? item.infoRu : item.infoUk}
                img={item.image?.url}
              />
            ))}
          </Slider>
        )}
      </Box>
    </Container>
  );
};
