const API_URL = "";

interface IClientBody {
	user_email: string;
	course_link: string;
}

export const sendEmail = async (clientBody: IClientBody) => {
	const response = await fetch(API_URL, {
		method: "POST",
		headers: {
			'Content-type': "application/json"
		},
		body: JSON.stringify(clientBody)
	});
	
	return response;
}
