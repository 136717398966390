import "./button-loader.scss";

export const ButtonLoader = () => (
  <div className="button-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
