import { toast } from "react-toastify";

export const notify = () => {
  toast.success("Заявка отправлена!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
