import React from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";

import { CustomButton } from "../../atoms/CustomButton/CustomButton";

import facebook from "../../assets/images/social-cards/facebook.svg";
import youtube from "../../assets/images/social-cards/youtube.svg";
import instagram from "../../assets/images/social-cards/instagram.svg";
import tiktok from "../../assets/images/social-cards/tiktok.svg";
import "./social-section.scss";
import { useTranslation } from "react-i18next";

const socialNetworks = [
  {
    id: 1,
    brandImg: facebook,
    subscribers: "171 000",
    link: "https://www.facebook.com/BondarBodymax",
  },
  {
    id: 2,
    brandImg: youtube,
    subscribers: "1 116 000",
    link: "https://youtube.com/c/Bondarbodymax",
  },
  {
    id: 3,
    brandImg: instagram,
    subscribers: "31 000",
    link: "https://www.instagram.com/dr_maksymbondar",
  },
  {
    id: 4,
    brandImg: tiktok,
    subscribers: "652 000",
    link: "https://www.tiktok.com/@dr_maksymbondar",
  },
];

export const SocialSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container fixed className="social">
      <Typography
        component="h3"
        variant="h4"
        color="primary"
        className="social__title"
      >
        {t("socials_section_title")}
      </Typography>
      <Grid container spacing={2} className="social__cards-container">
        {socialNetworks.map(({ id, brandImg, subscribers, link }, idx) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={id}
            data-aos="zoom-in-down"
            data-aos-delay={String(50 + idx * 100)}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className="social__card"
            >
              <img src={brandImg} height="42px" alt="Социальная сеть"/>
              <Typography color="primary" className="subscribers-info">
                {subscribers}+
              </Typography>
              <Typography color="textPrimary" className="subtitle">
                {t("socials.subscribers")}
              </Typography>
              <CustomButton
                component="a"
                target="_blank"
                href={link}
                color="secondary"
                variant="contained"
                text={t("socials.button") as string}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
