// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-mobile {
  width: 100%;
  border-radius: 20px;
  min-height: 440px;
  max-height: 440px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 20px;
}
.card-mobile img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: 268px;
}
.card-mobile__info {
  padding: 16px;
}
.card-mobile__info h5 {
  margin-bottom: 8px;
  font-size: 0.9rem;
}
.card-mobile__info .text-wrapper {
  overflow-y: auto;
  max-height: 130px;
}
.card-mobile__info .text-wrapper .MuiTypography-body1 {
  font-size: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/components/MassageCardMobile/massage-card-mobile.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;AAFF;AAIE;EACE,6BAAA;EACA,4BAAA;EACA,iBAAA;EACA,WAAA;EACA,aAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAII;EACE,kBAAA;EACA,iBAAA;AAFN;AAKI;EACE,gBAAA;EACA,iBAAA;AAHN;AAIM;EACE,iBAAA;AAFR","sourcesContent":["@import \"../../assets/sass/variables.scss\";\r\n@import \"../../assets/sass/media.scss\";\r\n\r\n.card-mobile {\r\n  width: 100%;\r\n  border-radius: 20px;\r\n  min-height: 440px;\r\n  max-height: 440px;\r\n  overflow: hidden;\r\n  background-color: #fff;\r\n  margin-top: 20px;\r\n\r\n  img {\r\n    border-top-right-radius: 20px;\r\n    border-top-left-radius: 20px;\r\n    object-fit: cover;\r\n    width: 100%;\r\n    height: 268px;\r\n  }\r\n\r\n  &__info {\r\n    padding: 16px;\r\n    h5 {\r\n      margin-bottom: 8px;\r\n      font-size: 0.9rem;\r\n    }\r\n\r\n    .text-wrapper {\r\n      overflow-y: auto;\r\n      max-height: 130px;\r\n      .MuiTypography-body1 {\r\n        font-size: 0.7rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
