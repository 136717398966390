// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courses-navbar {
  padding: 20px 0;
  margin: 0;
  margin-bottom: 55px;
}
@media screen and (max-width: 599px) {
  .courses-navbar {
    padding: 20px 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1279px) {
  .courses-navbar__logo {
    width: 120px;
    height: 60px;
  }
}
@media screen and (max-width: 959px) {
  .courses-navbar__logo {
    width: 100px;
    height: 50px;
  }
}
@media screen and (max-width: 599px) {
  .courses-navbar__logo {
    width: 73px;
    height: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/courses-page.scss","webpack://./src/assets/sass/media.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;EACA,mBAAA;AADF;ACSE;EDXF;IAKI,eAAA;IACA,mBAAA;EACF;AACF;ACTE;EDUE;IACE,YAAA;IACA,YAAA;EAEJ;AACF;ACTE;EDUE;IACE,YAAA;IACA,YAAA;EAEJ;AACF;ACTE;EDUE;IACE,WAAA;IACA,YAAA;EAEJ;AACF","sourcesContent":["@import \"../assets/sass/media.scss\";\r\n\r\n.courses-navbar {\r\n  padding: 20px 0;\r\n  margin: 0;\r\n  margin-bottom: 55px;\r\n  @include mobile {\r\n    padding: 20px 0;\r\n    margin-bottom: 40px;\r\n  }\r\n  @include laptop {\r\n    &__logo {\r\n      width: 120px;\r\n      height: 60px;\r\n    }\r\n  }\r\n  @include tablet {\r\n    &__logo {\r\n      width: 100px;\r\n      height: 50px;\r\n    }\r\n  }\r\n  @include mobile {\r\n    &__logo {\r\n      width: 73px;\r\n      height: 36px;\r\n    }\r\n  }\r\n}\r\n","@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
