import triggerPointLogo from "../assets/images/trigger-point-logo.webp";
import bodyMaxRehabLogo from "../assets/images/body-max-rehab-logo.webp";

export const courses = [
  {
    id: 2,
    title: "neck_course.title",
    text: "neck_course.text",
    list: [
      "neck_course.item_1",
      "neck_course.item_2",
      "neck_course.item_3",
      "neck_course.item_4",
      "neck_course.item_5",
    ],
    afterListText: "",
    actualPrice: 1200,
    previousPrice: 2000,
    youtubeLink: "https://youtu.be/o8q0JfCgAZM",
    courseLink: process.env.REACT_APP_NECK_COURSE_LINK || "",
  },
  {
    id: 1,
    title: "anathomy_course.title",
    text: "anathomy_course.text",
    list: [
      "anathomy_course.item_1",
      "anathomy_course.item_2",
      "anathomy_course.item_3",
    ],
    afterListText: "anathomy_course.additional_text",
    actualPrice: 1200,
    previousPrice: 2200,
    youtubeLink: "https://youtu.be/JRxG81zQllg",
    courseLink: process.env.REACT_APP_ANATHOMY_COURSE_LINK || "",
  },
  {
    id: 3,
    title: "percussion_course.title",
    text: "percussion_course.text",
    list: [
      "percussion_course.item_1",
      "percussion_course.item_2",
      "percussion_course.item_3",
      "percussion_course.item_4",
      "percussion_course.item_5",
    ],
    afterListText: "percussion_course.additional_text",
    actualPrice: 1200,
    previousPrice: 2200,
    youtubeLink: "https://youtu.be/6p0GZhggq8k",
    courseLink: process.env.REACT_APP_PERCUSSION_COURSE_LINK || "",
  },
  {
    id: 4,
    title: "trigger_points_course.title",
    text: "trigger_points_course.text",
    list: [
      "trigger_points_course.item_1",
      "trigger_points_course.item_2",
      "trigger_points_course.item_3",
    ],
    actualPrice: 1200,
    previousPrice: 2700,
    youtubeLink:
      "https://www.instagram.com/reel/C01bi7Ms3vQ/?igsh=eGMxdXJ4emx0em5x",
    courseLink: process.env.REACT_APP_MESSAGE_COURSE_LINK || "",
  },
  {
    id: 5,
    title: "panic_attacks_course.title",
    text: "panic_attacks_course.text",
    list: [
      "panic_attacks_course.item_1",
      "panic_attacks_course.item_2",
      "panic_attacks_course.item_3",
      "panic_attacks_course.item_4",
      "panic_attacks_course.item_5",
    ],
    actualPrice: 1200,
    previousPrice: 2200,
    youtubeLink: "https://youtu.be/3qXqfcAZkic",
    courseLink: process.env.REACT_APP_PANIC_COURSE_LINK || "",
  },
];

export const maskUserPhone = [
  "+",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const nameTestRegexp =
  /(^[А-я]+$)|(^[A-z]+$)|(^[A-z]+\s$)|(^[А-я]+\s$)|(^[А-я]+\s[А-я]+$)|(^[A-z]+\s[A-z]+$)/;

export const specialists = [
  {
    id: 0,
    name: "doctor_0.name",
    position: "doctor_0.position",
    imgPath: "/specialists/specialist-0.webp",
    abilities: ["doctor_0.skill_1", "doctor_0.skill_2", "doctor_0.skill_3"],
  },
  {
    id: 1,
    name: "doctor_1.name",
    position: "doctor_1.position",
    imgPath: "/specialists/specialist-1.webp",
    abilities: [
      "doctor_1.skill_1",
      "doctor_1.skill_2",
      "doctor_1.skill_3",
      "doctor_1.skill_4",
      "doctor_1.skill_5",
    ],
  },
  {
    id: 2,
    name: "doctor_2.name",
    position: "doctor_2.position",
    imgPath: "/specialists/specialist-2.webp",
    abilities: ["doctor_2.skill_1", "doctor_2.skill_2", "doctor_2.skill_3"],
  },
];

interface IAppsInfo {
  id: number;
  title: string;
  description: string;
  icon: string;
  variant: "bodymax" | "trigger";
}

export const APPS_INFO: IAppsInfo[] = [
  {
    id: 1,
    title: "BodyMax.Rehabilitation",
    description: "apps.body_max_app_description",
    icon: bodyMaxRehabLogo,
    variant: "bodymax",
  },
  {
    id: 2,
    title: "Trigger Point",
    description: "apps.trigger_points_app_description",
    icon: triggerPointLogo,
    variant: "trigger",
  },
];
