import { Grid, Hidden, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import doctorImg from "../../assets/images/hero-bg.webp";

import "./header-hero.scss";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import { Link } from "react-scroll";

export const HeaderHero = () => {
  const { t } = useTranslation();

  const imageAlt = t("doctor.surname") + " " + t("doctor.name");

  return (
    <Grid container className="hero" data-aos="zoom-in-up">
      <Grid item md={7} component="div" className="hero__info">
        <Typography
          component="h2"
          variant="h2"
          color="primary"
          style={{ textTransform: "uppercase" }}
        >
          {t("doctor.surname")}
        </Typography>
        <Typography
          component="h2"
          variant="h2"
          color="primary"
          style={{ textTransform: "uppercase" }}
        >
          {t("doctor.name")}
        </Typography>
        <Typography component="p" variant="subtitle1" color="primary">
          {t("doctor.caption")}
        </Typography>
        <Link to="feedback" smooth duration={1000}>
          <CustomButton
            variant="contained"
            color="secondary"
            text={t("doctor.button")}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={5} className="hero__img">
        <Hidden mdUp>
          <div className="overlay" />
        </Hidden>
        <img src={doctorImg} alt={imageAlt}/>
      </Grid>
    </Grid>
  );
};
