import './loader.scss';

interface IProps {
  fixed?: boolean;
}

export const Loader = ({fixed = true}: IProps) => {
	return (
		<div className={fixed ? "loadingio-spinner-ripple-1ijn2xeva1q" : "loadingio-spinner-ripple-nofixed"}>
			<div className="ldio-78weyfw7qxf">
				<div></div>
				<div></div>
			</div>
		</div>
	)
}
