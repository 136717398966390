import React, { useContext, useEffect } from "react";
import { Link as ScrollTo } from "react-scroll";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { CustomButton } from "../../atoms/CustomButton/CustomButton";

import { sha1 } from "../../utils/sha1";
import { sendEmail } from "../../utils/sendEmail";

import "./separate-course.scss";

declare global {
  interface Window {
    LiqPayCheckoutCallback: any;
  }
}

interface IProps {
  id: number;
  title: string;
  text: string;
  afterListText?: string;
  list?: string[];
  actualPrice: number;
  previousPrice: number | null;
  youtubeLink: string;
  courseLink: string;
  setShowModal: (show: boolean) => void;
  CoursesContext: any;
  showModal: boolean;
  mainPage: boolean;
}

export const SeparateCourse: React.FC<IProps> = ({
  id,
  showModal,
  title,
  text,
  afterListText,
  list,
  actualPrice,
  previousPrice,
  youtubeLink,
  setShowModal,
  CoursesContext,
  courseLink,
  mainPage,
}) => {
  const setCurrentCourseLink = useContext(CoursesContext);
  // @ts-ignore
  const order = JSON.parse(localStorage.getItem("userOrder"));

  const { t } = useTranslation();

  useEffect(() => {
    if (
      !showModal &&
      order &&
      order.user_email.length > 0 &&
      order.course_link === courseLink
    ) {
      showModal = true
      buyCourse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const buyCourse = async () => {
    const liqpayParams = {
      public_key: process.env.REACT_APP_PUBLIC_KEY,
      version: "3",
      action: "pay",
      amount: actualPrice.toString(),
      currency: "UAH",
      description: title,
      order_id: `f${(~~(Math.random() * 1e8)).toString(16)}`,
    };

    const data = btoa(
      unescape(encodeURIComponent(JSON.stringify(liqpayParams)))
    );
    let signature: string = await sha1(
      process.env.REACT_APP_PRIVATE_KEY +
        data +
        process.env.REACT_APP_PRIVATE_KEY
    );
    let called = false;

    window
      .LiqPayCheckoutCallback(data, signature)
      .init({
        data,
        signature,
        mode: "popup",
      })
      .on("liqpay.callback", function (data: any) {
        if (data && data.status === "success" && !called) {
          sendEmail(order);
          called = true;
        }
      })
      .on("liqpay.ready", function () {
        document.body.style.overflowY = "hidden";
      })
      .on("liqpay.close", function () {
        localStorage.clear();
        document.body.style.overflowY = "auto";
      });
  };

  const imageSource = (() => {
    switch (id) {
      case 1:
        return "/courses/anatomy/anatomy";
      case 2:
        return "/courses/neck/neck";
      case 3:
        return "/courses/percussion/percussion";
      case 4:
        return "/courses/massage/massage";
      case 5:
        return "/courses/panic/panic";
    }
  })();

  const handleBuyCourse = () => {
    if (courseLink) {
      // @ts-ignore
      setCurrentCourseLink(courseLink);
      setShowModal(true);
    }
  };

  return (
    <Grid container className="separate-course">
      <Grid item className="separate-course__img" xs={12} md={5}>
        <img
          srcSet={`
              ${imageSource}-xs.webp 300w,
          		${imageSource}-s.webp 500w,
              ${imageSource}-m.webp 700w,
              ${imageSource}-l.webp 880w,
              ${imageSource}-xl.webp 1024w
          `}
          sizes="
          (max-width: 599px) 380px,
          (max-width: 959px) 560px,
          (max-width: 1279px) 380px,
          1024px"
          src={`/courses/${imageSource}-xl.webp`}
          alt={t(title) as string}
        />
      </Grid>
      <Grid item className="separate-course__info" md={6}>
        <Typography component="h4" variant="h6" color="primary">
          {t(title)}
        </Typography>
        <Typography component="div" variant="body1" color="textPrimary">
          {t(text)}
          {list && (
            <List>
              {list.map((itemText) => (
                <ListItem key={itemText + Date.now()}>
                  <ListItemText>{t(itemText)}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
          {afterListText ? t(afterListText) : null}
        </Typography>
        <Box display="flex">
          <Typography component="p" variant="h6" color="primary">
            {actualPrice} грн
            {previousPrice && (
              <Typography component="sub" variant="h6" color="textSecondary">
                {previousPrice} грн
              </Typography>
            )}
          </Typography>
        </Box>
        <Box display="flex" className="separate-course__buttons">
          <CustomButton
            variant="contained"
            color="secondary"
            text={t("buy_button") as string}
            disabled={!Boolean(courseLink)}
            onClick={handleBuyCourse}
          />
          {mainPage && (
            <ScrollTo to="reviews" smooth duration={1000}>
              <CustomButton
                variant="outlined"
                color="secondary"
                text={t("reviews_button") as string}
              />
            </ScrollTo>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className="separate-course__youtube"
        >
          <img src="/courses/youtube-logo.svg" alt="Youtube"/>
          <Link
            href={youtubeLink}
            target="_blank"
            rel="norefferer noopener"
            underline="none"
            color="secondary"
            variant="body2"
          >
            {t("preview_video_button")}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};
