// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 599px) {
  .app_title.MuiTypography-h6 {
    font-size: 1.4rem;
  }
}
.app_description {
  margin-top: 14px;
}
.app_button {
  display: block;
  margin-top: 20px;
}
.app img {
  width: 85px;
  height: 85px;
  border: 1px solid rgba(189, 151, 114, 0.5);
  border-radius: 8px;
  margin-left: 14px;
}
@media screen and (max-width: 599px) {
  .app img {
    width: 55px;
    height: 55px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/sass/media.scss","webpack://./src/components/AppsSection/AppCard/app-card.scss"],"names":[],"mappings":"AAaE;ECVA;IAEI,iBAAA;EAFJ;AACF;AAIE;EACE,gBAAA;AAFJ;AAIE;EACE,cAAA;EACA,gBAAA;AAFJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;ADLE;ECEA;IAOI,WAAA;IACA,YAAA;EAAJ;AACF","sourcesContent":["@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n","@import \"../../../assets/sass/media.scss\";\r\n\r\n.app {\r\n  &_title.MuiTypography-h6 {\r\n    @include mobile {\r\n      font-size: 1.4rem;\r\n    }\r\n  }\r\n  &_description {\r\n    margin-top: 14px;\r\n  }\r\n  &_button {\r\n    display: block;\r\n    margin-top: 20px;\r\n  }\r\n  img {\r\n    width: 85px; \r\n    height: 85px; \r\n    border: 1px solid rgba(189, 151, 114, 0.5);\r\n    border-radius: 8px;\r\n    margin-left: 14px;\r\n    @include mobile {\r\n      width: 55px;\r\n      height: 55px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
