import { 
  TRIGGER_POINT_APPSTORE_LINK, 
  TRIGGER_POINT_PLAYMARKET_LINK, 
  BODYMAX_REHAB_APPSTORE_LINK, 
  BODYMAX_REHAB_PLAYMARKET_LINK
} from '../consts/index';

const useDevice = () => {
  const userAgent = navigator.userAgent;

  let triggerPointLink = '';
  let bodyMaxRehabLink = '';

  if (userAgent.indexOf("Mac") !== -1) {
    triggerPointLink = TRIGGER_POINT_APPSTORE_LINK;
    bodyMaxRehabLink = BODYMAX_REHAB_APPSTORE_LINK;
  } else {
    triggerPointLink = TRIGGER_POINT_PLAYMARKET_LINK;
    bodyMaxRehabLink = BODYMAX_REHAB_PLAYMARKET_LINK;
  }

  return {
    triggerPointLink,
    bodyMaxRehabLink
  }
}

export default useDevice;