import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { CustomButton } from "../../../atoms/CustomButton/CustomButton";

import "./app-card.scss";

interface AppCardProps {
  title: string;
  icon: string;
  description: string;
  link: string;
}

export const AppCard: React.FC<AppCardProps> = ({
  title,
  icon,
  description,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item md={6} sm={12} className="app">
      <Box display="flex" alignItems="center">
        <Typography variant="h6" color="primary" className="app_title">
          {title}
        </Typography>
        <img src={icon} alt="BodyMaxRehab"/>
      </Box>
      <Typography
        variant="body1"
        color="textPrimary"
        className="app_description"
      >
        {t(description)}
      </Typography>
      <a href={link} target="_blank" className="app_button">
        <CustomButton
          color="secondary"
          variant="contained"
          text={t("apps_download_button") as string}
        />
      </a>
    </Grid>
  );
};
