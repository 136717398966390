import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { specialists } from "../../static";

import "./specialists-section.scss";

export const SpecialistsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      component="section"
      className="specialists"
      id="specialists"
      fixed
    >
      <Typography
        component="h3"
        variant="h4"
        color="primary"
        className="specialists__title"
      >
        {t('doctors_section_title')}
      </Typography>
      <ul>
        {specialists.map(({ id, name, position, imgPath, abilities }) => (
          <Grid container key={id} spacing={3}>
            <Grid item md={6} sm={12} className="specialists__info">
              <Typography component="h4" variant="h6" color="primary">
                {t(name)}
              </Typography>
              <Typography component="h5" variant="h3" color="textPrimary">
                {t(position)}
              </Typography>
              <ul className="abilities">
                {abilities.map((ability) => (
                  <li key={ability}>
                    <Typography
                      component="span"
                      variant="body1"
                      color="textPrimary"
                    >
                      {t(ability)}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="image-wrapper">
              <img src={imgPath} className="specialists__image" alt={name}/>
            </Grid>
          </Grid>
        ))}
      </ul>
    </Container>
  );
};
