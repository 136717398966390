import { useEffect, useState } from "react";

type Function = (bool?: boolean) => void;

export const useModal = (closeModal?: Function, courseLink?: string) => {
	const [userEmail, setUserEmail] = useState('');
	const [isError, setError] = useState<boolean | undefined>(false);
	const emailRegexp = /^.+@.+\..+$/igm;

	useEffect(() => {
		document.body.style.overflowY = "hidden";

		return () => {
			document.body.style.overflowY = "auto";
		};
	}, []);

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		const params = {
			user_email: userEmail,
			course_link: courseLink
		};
		
		if(emailRegexp.test(userEmail)) {
			localStorage.setItem('userOrder', JSON.stringify(params));
			closeModal!();
		} else {
			setError(true);
			setUserEmail("");
		}
	}

	const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
		setUserEmail(e.currentTarget.value);
		setError(false);
	}

	return { handleSubmit, handleEmailChange, userEmail, isError }
};