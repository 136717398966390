import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Container, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { SeparateCourse } from "../SeparateCourse/SeparateCourse";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import { Modal } from "../Modal/Modal";

import { courses } from "../../static/index";
import "./courses-section.scss";

interface IProps {
  mainPage?: boolean;
}

export const CoursesSection: React.FC<IProps> = ({ mainPage = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentCourseLink, setCurrentCourseLink] = useState("");

  const { t } = useTranslation();

  const CoursesContext = React.createContext(setCurrentCourseLink);

  const node = document.querySelector("#portal") as HTMLDivElement;

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    sessionStorage.setItem('liqpayExecuted', 'false');
    localStorage.clear();
  }, []);

  const coursesToRender = mainPage ? courses.slice(0, 1) : courses;

  return (
    <CoursesContext.Provider value={setCurrentCourseLink}>
      <Container fixed component="section" className="courses" id="courses">
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="courses__title"
        >
          {t("courses_section_title")}
        </Typography>
        {coursesToRender.map(({ id, ...restProps }) => (
          <SeparateCourse
            CoursesContext={CoursesContext}
            showModal={showModal}
            setShowModal={setShowModal}
            key={id}
            id={id}
            mainPage={mainPage}
            {...restProps}
          />
        ))}
        {mainPage && (
          <Box className="courses__link-to-courses-page">
            <Link to="/courses">
              <CustomButton
                color="secondary"
                variant="contained"
                text={t("more_courses_button") as string}
              />
            </Link>
          </Box>
        )}
      </Container>

      {showModal
        ? ReactDOM.createPortal(
            <Modal closeModal={closeModal} courseLink={currentCourseLink} />,
            node
          )
        : null}
    </CoursesContext.Provider>
  );
};
