// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  font-size: 1rem;
  width: 181px;
  height: 52px;
  display: block;
}
@media screen and (max-width: 959px) {
  .custom-button {
    width: 151px;
    height: 42px;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 599px) {
  .custom-button {
    width: 135px;
    height: 36px;
    font-size: 0.7rem;
  }
}
.custom-button.MuiButton-root:disabled {
  background-color: rgba(189, 151, 114, 0.5);
}
.custom-button .MuiButton-label {
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/atoms/CustomButton/custom-button.scss","webpack://./src/assets/sass/media.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;AADF;ACEE;EDLF;IAMI,YAAA;IACA,YAAA;IACA,iBAAA;EACF;AACF;ACCE;EDXF;IAWI,YAAA;IACA,YAAA;IACA,iBAAA;EAGF;AACF;AAFE;EACE,0CAAA;AAIJ;AAFE;EACE,oBAAA;AAIJ","sourcesContent":["@import \"../../assets/sass/media.scss\";\r\n\r\n.custom-button {\r\n  font-size: 1rem;\r\n  width: 181px;\r\n  height: 52px;\r\n  display: block;\r\n  @include tablet {\r\n    width: 151px;\r\n    height: 42px;\r\n    font-size: 0.8rem;\r\n  }\r\n  @include mobile {\r\n    width: 135px;\r\n    height: 36px;\r\n    font-size: 0.7rem;\r\n  }\r\n  &.MuiButton-root:disabled {\r\n    background-color: rgba(189, 151, 114, 0.5);\r\n  }\r\n  .MuiButton-label {\r\n    text-transform: none;\r\n  }\r\n}\r\n","@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
