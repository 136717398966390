import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/bodymax-logo.webp";
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import "./navbar.scss";
import { Link } from "react-scroll";
import { Close, Menu } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const menuItems = [
  {
    id: 1,
    label: "Массажи",
    targetHref: "massage",
  },
  // {
  // 	id: 2,
  // 	label: "Обучение",
  // 	targetHref: "education"
  // },
  {
    id: 3,
    label: "Онлайн курсы",
    targetHref: "courses",
  },
  {
    id: 4,
    label: "Прайс",
    targetHref: "price",
  },
  {
    id: 5,
    label: "Отзывы",
    targetHref: "reviews",
  },
  {
    id: 6,
    label: "Контакты",
    targetHref: "contacts",
  },
];

interface IProps {
  withLogo?: boolean;
}

export const Navbar: React.FC<IProps> = ({ withLogo }) => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const { t } = useTranslation();

  let overlayClasses = "navbar__overlay";

  useEffect(() => {
    if (mobileMenuOpened) {
      document.querySelector("body")?.classList.add("overflow-hidden");
    } else if (!mobileMenuOpened) {
      document.querySelector("body")?.classList.remove("overflow-hidden");
    }

    return () =>
      document.querySelector("body")?.classList.remove("overflow-hidden");
  }, [mobileMenuOpened]);

  if (mobileMenuOpened) {
    overlayClasses += " navbar__overlay_active";
  } else if (!mobileMenuOpened) {
    overlayClasses = "navbar__overlay";
  }

  const preloadImage = (src: string) => {
    const img = new Image();
    img.src = src;
  };

  useEffect(() => {
    preloadImage("https://bodymax.online/static/media/bodymax-logo.c5a94161f639a18b219f.webp");
  }, []);

  const toggleMenu = () => setMobileMenuOpened((prevState) => !prevState);
  const closeMenu = () => {
    setMobileMenuOpened(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className="navbar"
    >
      {withLogo && (
        <Grid xs={2} item component="div" className="navbar__logo">
          <img src={Logo} alt="Bodymax" loading="lazy"/>
        </Grid>
      )}
      {withLogo ? (
        <>
          <Hidden smDown>
            <Grid xs={withLogo ? 6 : 12} xl={4} item className="nav-wrapper">
              <div>
                <Typography
                  component="a"
                  href="https://goo.gl/maps/tysJSDs7K8jyvcjK7"
                  className="address"
                  target="_blank"
                  color="textPrimary"
                  variant="body1"
                >
                  {t("address")}
                </Typography>
                <Typography
                  component="a"
                  href="tel:+380509965325"
                  color="textPrimary"
                  variant="body1"
                  className="tel"
                >
                  +38 (050) 996-53-25
                </Typography>
              </div>
              <Box component="ul" className="navbar__menu">
                {menuItems.map(({ id, label, targetHref }) => (
                  <li key={id} className="item">
                    <Link to={targetHref} smooth duration={1000}>
                      <Typography component="span" color="primary">
                        {t(`navbar.${targetHref}`)}
                      </Typography>
                    </Link>
                  </li>
                ))}
              </Box>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <IconButton className="navbar__menu-btn" onClick={toggleMenu}>
              {mobileMenuOpened ? (
                <Close color="secondary" />
              ) : (
                <Menu color="secondary" />
              )}
            </IconButton>
            <Box className={overlayClasses}>
              <Grid xs={12} item component="ul" className="navbar__menu">
                {menuItems.map(({ id, label, targetHref }) => (
                  <li key={id} className="item">
                    <Link
                      to={targetHref}
                      smooth
                      duration={1000}
                      onClick={closeMenu}
                    >
                      <Typography component="span" color="primary">
                        {t(`navbar.${targetHref}`)}
                      </Typography>
                    </Link>
                  </li>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  component="a"
                  href="viber://add?number=380509965325"
                >
                  {t("contact_us_button")}
                </Button>
                <div style={{ margin: "36px 0 36px 13px" }}>
                  <Typography
                    component="a"
                    href="https://goo.gl/maps/tysJSDs7K8jyvcjK7"
                    className="address no-margin"
                    target="_blank"
                    color="textPrimary"
                    variant="body1"
                  >
                    {t("address")}
                  </Typography>
                  <Typography
                    component="a"
                    href="tel:+380509965325"
                    color="textPrimary"
                    variant="body1"
                    className="tel"
                  >
                    +38 (050) 996-53-25
                  </Typography>
                </div>
              </Grid>
            </Box>
          </Hidden>
        </>
      ) : (
        <Grid
          xs={withLogo ? 7 : 12}
          item
          component="ul"
          className="navbar__menu"
        >
          {menuItems.map(({ id, label, targetHref }) => (
            <li key={id} className="item">
              <Link to={targetHref} smooth duration={1000}>
                <Typography component="span" color="primary">
                  {label}
                </Typography>
              </Link>
            </li>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
