import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import "./separate-review.scss";

interface IProps {
  avatar: string;
  name: string;
  status: React.ReactNode;
  review: string;
}

export const SeparateReview: React.FC<IProps> = ({
  avatar,
  name,
  status,
  review,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="review-card non-selectable"
    >
      <Box display="flex" className="review-card__header">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="review-card__avatar-name-container"
        >
          {!imageError ? (
            <img
              src={avatar}
              alt={name}
              className="review-card__avatar"
              onError={handleImageError}
            />
          ) : (
            <div className="review-card__avatar review-card__avatar--placeholder">
              {name.charAt(0)}
            </div>
          )}
          <Typography component="h5" variant="body2" color="primary">
            {name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box className="review-card__status">{status}</Box>
        </Box>
      </Box>
      <Typography
        component="p"
        variant="body1"
        color="textPrimary"
        className="review-card__text"
      >
        {review}
      </Typography>
    </Box>
  );
};
