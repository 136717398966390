export const requestForFeedback = async (
  userName: string,
  userPhone: string
) => {
  const API_URL = "https://bodymax.online/alerts/feedback";
  return await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: userName, phone: userPhone }),
  });
};
