// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-loader {
  height: 20px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.button-loader div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.button-loader div:nth-child(1) {
  left: 0;
  animation: lds-ellipsis1 0.6s infinite;
}
.button-loader div:nth-child(2) {
  left: 0px;
  animation: lds-ellipsis2 0.6s infinite;
}
.button-loader div:nth-child(3) {
  left: 18px;
  animation: lds-ellipsis2 0.6s infinite;
}
.button-loader div:nth-child(4) {
  right: 0;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/atoms/ButtonLoader/button-loader.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,mDAAA;AAEJ;AAAE;EACE,OAAA;EACA,sCAAA;AAEJ;AAAE;EACE,SAAA;EACA,sCAAA;AAEJ;AAAE;EACE,UAAA;EACA,sCAAA;AAEJ;AAAE;EACE,QAAA;EACA,sCAAA;AAEJ;AAAE;EACE;IACE,mBAAA;EAEJ;EAAE;IACE,mBAAA;EAEJ;AACF;AAAE;EACE;IACE,mBAAA;EAEJ;EAAE;IACE,mBAAA;EAEJ;AACF;AAAE;EACE;IACE,0BAAA;EAEJ;EAAE;IACE,6BAAA;EAEJ;AACF","sourcesContent":[".button-loader {\r\n  height: 20px;\r\n  width: 45px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  position: relative;\r\n  div {\r\n    position: absolute;\r\n    width: 10px;\r\n    height: 10px;\r\n    border-radius: 50%;\r\n    background: #fff;\r\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\r\n  }\r\n  div:nth-child(1) {\r\n    left: 0;\r\n    animation: lds-ellipsis1 0.6s infinite;\r\n  }\r\n  div:nth-child(2) {\r\n    left: 0px;\r\n    animation: lds-ellipsis2 0.6s infinite;\r\n  }\r\n  div:nth-child(3) {\r\n    left: 18px;\r\n    animation: lds-ellipsis2 0.6s infinite;\r\n  }\r\n  div:nth-child(4) {\r\n    right: 0;\r\n    animation: lds-ellipsis3 0.6s infinite;\r\n  }\r\n  @keyframes lds-ellipsis1 {\r\n    0% {\r\n      transform: scale(0);\r\n    }\r\n    100% {\r\n      transform: scale(1);\r\n    }\r\n  }\r\n  @keyframes lds-ellipsis3 {\r\n    0% {\r\n      transform: scale(1);\r\n    }\r\n    100% {\r\n      transform: scale(0);\r\n    }\r\n  }\r\n  @keyframes lds-ellipsis2 {\r\n    0% {\r\n      transform: translate(0, 0);\r\n    }\r\n    100% {\r\n      transform: translate(18px, 0);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
