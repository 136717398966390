// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
}

body {
  background-color: #e5e5e5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(68, 61, 61, 0.5019607843);
}

::-webkit-scrollbar-thumb {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #443d3d;
  border-radius: 9em;
}

body.overflow-hidden {
  overflow: hidden;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.MuiAlert-root {
  font-size: 16px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/assets/sass/variables.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,yBCZc;EDad,SAAA;EACA,UAAA;EACA,kBAAA;EACA,kCAAA;EACA,mCAAA;EACA,kCAAA;AAFF;;AAKA;EACE,UAAA;EACA,WAAA;EACA,gDAAA;AAFF;;AAIA;EACE,iCAAA;EAAA,yBAAA;EACA,yBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,UAAA;EACA,SAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"./assets/sass/variables.scss\";\r\n@import \"./assets/sass/media.scss\";\r\n\r\n* {\r\n  box-sizing: border-box;\r\n}\r\n\r\nhtml {\r\n  font-size: 18px;\r\n}\r\n\r\nbody {\r\n  background-color: $main-bg-color;\r\n  margin: 0;\r\n  padding: 0;\r\n  overflow-x: hidden;\r\n  font-family: \"Raleway\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n  height: 8px;\r\n  background-color: #443d3d80;\r\n}\r\n::-webkit-scrollbar-thumb {\r\n  transition: all 0.3s ease;\r\n  background-color: #443d3d;\r\n  border-radius: 9em;\r\n}\r\n\r\nbody.overflow-hidden {\r\n  overflow: hidden;\r\n}\r\n\r\na {\r\n  text-decoration: none;\r\n}\r\n\r\nul {\r\n  padding: 0;\r\n  margin: 0;\r\n  list-style: none;\r\n}\r\n\r\n.MuiAlert-root {\r\n  font-size: 16px;\r\n  align-items: center;\r\n}\r\n","$main-bg-color: #e5e5e5;\r\n$primary: #443d3d;\r\n$secondary: #987656;\r\n$secondary-opacity: #9876569c;\r\n$secondary-hover-btn: #98765625;\r\n$sub-text: #a19e9e;\r\n$hover-color: #aa8663;\r\n$input-bg: #edeae7;\r\n$text-primary: #777170;\r\n$error: #ee4444;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
