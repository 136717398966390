// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courses {
  margin-bottom: 150px;
}
@media screen and (max-width: 959px) {
  .courses {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 599px) {
  .courses {
    margin-bottom: 40px;
  }
}
.courses__link-to-courses-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.courses__link-to-courses-page .custom-button {
  margin-top: 80px;
}
@media screen and (max-width: 1279px) {
  .courses__link-to-courses-page .custom-button {
    margin-top: 60px;
  }
}
@media screen and (max-width: 959px) {
  .courses__link-to-courses-page .custom-button {
    margin-top: 70px;
  }
}
@media screen and (max-width: 599px) {
  .courses__link-to-courses-page .custom-button {
    width: 146px;
    margin-top: 60px;
  }
}
.courses__title.MuiTypography-root {
  margin-bottom: 70px;
}
@media screen and (max-width: 1279px) {
  .courses__title.MuiTypography-root {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 959px) {
  .courses__title.MuiTypography-root {
    font-size: 1.6rem;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 599px) {
  .courses__title.MuiTypography-root {
    font-size: 1.1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CoursesSection/courses-section.scss","webpack://./src/assets/sass/media.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;ACKE;EDLF;IAGI,mBAAA;EACF;AACF;ACME;EDXF;IAMI,mBAAA;EAGF;AACF;AAFE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAIJ;AAFE;EACE,gBAAA;AAIJ;ACnBE;EDcA;IAGI,gBAAA;EAMJ;AACF;AClBE;EDQA;IAMI,gBAAA;EAQJ;AACF;ACjBE;EDEA;IASI,YAAA;IACA,gBAAA;EAUJ;AACF;AARE;EACE,mBAAA;AAUJ;ACtCE;ED2BA;IAGI,iBAAA;EAYJ;AACF;ACrCE;EDqBA;IAMI,iBAAA;IACA,mBAAA;EAcJ;AACF;ACrCE;EDeA;IAUI,iBAAA;EAgBJ;AACF","sourcesContent":["@import \"../../assets/sass/media.scss\";\r\n\r\n.courses {\r\n  margin-bottom: 150px;\r\n  @include tablet {\r\n    margin-bottom: 80px;\r\n  }\r\n  @include mobile {\r\n    margin-bottom: 40px;\r\n  }\r\n  &__link-to-courses-page {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  &__link-to-courses-page .custom-button {\r\n    margin-top: 80px;\r\n    @include laptop {\r\n      margin-top: 60px;\r\n    }\r\n    @include tablet {\r\n      margin-top: 70px;\r\n    }\r\n    @include mobile {\r\n      width: 146px;\r\n      margin-top: 60px;\r\n    }\r\n  }\r\n  &__title.MuiTypography-root {\r\n    margin-bottom: 70px;\r\n    @include laptop {\r\n      font-size: 1.9rem;\r\n    }\r\n    @include tablet {\r\n      font-size: 1.6rem;\r\n      margin-bottom: 40px;\r\n    }\r\n    @include mobile {\r\n      font-size: 1.1rem;\r\n    }\r\n  }\r\n}\r\n","@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
