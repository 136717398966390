import React from "react";
import { Typography } from "@material-ui/core";

import "./treatment-card.scss";
import { useTranslation } from "react-i18next";

export const TreatmentCard: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div className="treatment-card">
      <div className="treatment-card__image" />
      <div className="treatment-card__info">
        <Typography
          component="h3"
          variant="h3"
          color="primary"
          className="treatment-card__title"
        >
          {t('treatment_results.before')}
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color="primary"
          className="treatment-card__title"
        >
          {t('treatment_results.after')}
        </Typography>
      </div>
    </div>
  );
};
