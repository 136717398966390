import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Container, Grid, Typography } from "@material-ui/core";

import "./price-section.scss";
import { getPrices } from "../../services";
import i18n from "../../i18n";
import { Loader } from "../Loader/Loader";

interface PriceItem {
  price: string;
  optionRu: string | null;
  option_uk: string | null;
  titleRu: string;
  titleUk: string;
  priceOption: boolean;
  id: string;
}

interface PriceList {
  priceApi: PriceItem[];
}

export const PriceSection: React.FC = () => {
  const { t } = useTranslation();
  const [priceList, setPriceList] = useState<PriceList>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchPrices = async () => {
    try {
      const res = await getPrices();
      const priceListData = res as PriceList;
      setPriceList(priceListData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  return (
    <Grid component="section" className="price-section" id="price">
      <Container fixed>
        <Grid container justifyContent="space-between">
          <Grid
            container
            item
            xs={12}
            md={5}
            alignItems="center"
            justifyContent="center"
          >
            <img
              srcSet="
                /price-section/price-section-xs.webp 300w,
                /price-section/price-section-s.webp 572w,
                /price-section/price-section-m.webp 761w,
                /price-section/price-section-l.webp 906w,
                /price-section/price-section-xl.webp 1024w"
              sizes="
                (max-width: 599px) 380px,
                (max-width: 959px) 510px,
                (max-width: 1279px) 380px,
                1024px"
              src="/feedback/feedback-xl.webp"
              alt="Massage"
            />
          </Grid>
          <Grid item md={6}>
            <Typography
              component="h4"
              variant="h4"
              color="primary"
              className="price-section__title"
            >
              {t("prices_section_title")}
            </Typography>
            <ul className="price-section__list-with-additional-services list-with-additional-services">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Loader fixed={false} />
                </div>
              ) : (
                priceList?.priceApi.map((item: PriceItem) => {
                  return (
                    <li
                      className="list-with-additional-services__item list-item"
                      key={item.id}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          component="span"
                          color="primary"
                          variant="body2"
                          className="price-section__name-service"
                        >
                          {i18n.language === "ru" || i18n.language === "ru-RU"
                            ? item.titleRu
                            : item.titleUk}
                        </Typography>
                        <span className="price-section__price-seance">
                          {" "}
                          {item.priceOption && t("prices_options.item_1")}{" "}
                          {item.price} грн{" "}
                          {item.optionRu &&
                            `(${
                              i18n.language === "ru" || i18n.language === "ru-RU"
                                ? item.optionRu
                                : item.option_uk
                            })`}
                        </span>
                      </Box>
                    </li>
                  );
                })
              )}
            </ul>
            <Typography
              component="p"
              variant="body1"
              color="textPrimary"
              className="price-section__warning"
            >
              * {t("prices_section_subtitle_2")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
