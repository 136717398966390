import React from "react";
import { Box, Typography } from "@material-ui/core";

import "./massage-card-mobile.scss";

interface IProps {
  title: string;
  text: string;
  id: number;
  img: string;
}

export const MassageCardMobile: React.FC<IProps> = ({ title, text, img }) => {
  return (
    <>
      <div className="card-mobile">
        <div>
          <img src={img} alt={title}/>
        </div>
        <div className="card__info">
          <Typography component="h5" variant="h5" color="primary">
            {title}
          </Typography>
          <Box className="text-wrapper">
            <Typography component="p" variant="body1" color="textPrimary">
              {text}
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
};
