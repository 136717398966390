import { useEffect, useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@material-ui/core";
import { SeparateReview } from "../SeparateReview/SeparateReview";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import "./reviews-section.scss";

interface Review {
  author_name: string;
  author_url: string;
  language: string;
  original_language: string;
  profile_photo_url: string;
  rating: number;
  relative_time_description: string;
  text: string;
  time: number;
  translated: boolean;
}

const renderStars = (rating: number) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      i <= rating ? (
        <StarIcon key={i} color="secondary" />
      ) : (
        <StarBorderIcon key={i} color="secondary" />
      )
    );
  }
  return stars;
};

export const ReviewsSection = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch("/api/reviews");
        const data = await response.json();
        if (data.status === "OK") {
          setReviews(data.reviews);
        } else {
          console.error("Failed to fetch reviews:", data.message);
        }
      } catch (error: any) {
        console.error("Error fetching reviews:", error.message);
      }
    };

    fetchReviews();
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    cssEase: "ease",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
          waitForAnimate: false,
          swipe: true,
          autoplay: false,
          swipeToSlide: true,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <section className="reviews" id="reviews">
      <Container fixed>
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="reviews__title"
        >
          {t("reviews_section_title")}
        </Typography>
      </Container>
      <Slider {...settings} className="reviews__slider">
        {reviews.map((review) => (
          <SeparateReview
            key={review.author_name}
            avatar={review.profile_photo_url}
            name={review.author_name}
            status={
              <div className="review__meta">
                <span className="review__stars">
                  {renderStars(review.rating)}
                </span>
                <span className="review__time">
                  {review.relative_time_description}
                </span>
              </div>
            }
            review={review.text}
          />
        ))}
      </Slider>
    </section>
  );
};
