import { MassageSection } from "../MassageSection/MassageSection";
import { SpecialistsSection } from "../SpecialistsSection/SpecialistsSection";
// import { Education } from "../Education/Education";
import { CoursesSection } from "../CoursesSection/CoursesSection";
import { ReviewsSection } from "../ReviewsSection/ReviewsSection";
import { SocialSection } from "../SocialSection/SocialSection";
import { ContactsSection } from "../ContactsSection/ContactsSection";
import { Feedback } from "../Feedback/Feedback";
import { PriceSection } from "../PriceSection/PriceSection";
import { TreatmentResults } from "../TreatmentResults/TreatmentResults";
import { AppsSection } from "../AppsSection/AppsSection";

import "./content.scss";

export const Content = () => {
  return (
    <main>
      <MassageSection />
      <SpecialistsSection />
      {/* <Education /> */}
      <CoursesSection mainPage />
      <AppsSection />
      <TreatmentResults />
      <PriceSection />
      <ReviewsSection />
      <SocialSection />
      <ContactsSection />
      <Feedback />
    </main>
  );
};
