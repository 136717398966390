import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./massage-card.scss";

interface IProps {
  title: string;
  text: string;
  img: string;
}

export const MassageCard: React.FC<IProps> = ({ title, text, img }) => {  
  return (
    <div className="card">
      <div
        className="card__image"
        style={{
          background: `url('${img}')`,
        }}
      />
      <div className="card__info">
        <Typography component="h5" variant="h5" color="primary">
          {title}
        </Typography>
        <Box className="text-wrapper">
          <Typography component="p" variant="body1" color="textPrimary">
            {text}
          </Typography>
        </Box>
      </div>
    </div>
  );
};
