// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  border-bottom: 1px solid #FFF;
  padding-left: 10%;
  padding-right: 10%;
}
.header .container {
  min-height: 100vh;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;AADF;AAGE;EACE,iBAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"../../assets/sass/variables.scss\";\r\n\r\n.header {\r\n  border-bottom: 1px solid #FFF;\r\n  padding-left: 10%;\r\n  padding-right: 10%;\r\n  \r\n  .container {\r\n    min-height: 100vh;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
