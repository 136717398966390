import React from 'react';
import { Container, Grid } from "@material-ui/core";

import useDevice from "../../hooks/useDevice";

import { AppCard } from './AppCard/AppCard';
import { APPS_INFO } from '../../static';

export const AppsSection: React.FC = () => {
  const { triggerPointLink, bodyMaxRehabLink } = useDevice();

  return (
    <Container component="section" fixed>
      <Grid container spacing={4}>
        {APPS_INFO.map(app => (
          <AppCard 
            key={app.id} 
            title={app.title} 
            description={app.description} 
            icon={app.icon} 
            link={app.variant === "trigger" ? triggerPointLink : bodyMaxRehabLink} 
          />
        ))}
      </Grid>
    </Container>
  )
}