import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import { IconButton, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { ButtonLoader } from "../../atoms/ButtonLoader/ButtonLoader";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";

import { maskUserPhone } from "../../static/index";
import { useFeedback } from "../Feedback/useFeeedback";
import errorIcon from "../../assets/images/email-error.svg";

import "./feedback-alert.scss";
import { useTranslation } from "react-i18next";

export const FeedbackAlert: React.FC = () => {
  const { userName, userPhone, isLoading, errors, handleSubmit, handleChange } =
    useFeedback();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal open={openModal} className="feedback-alert-overlay">
      <form className="feedback-alert" onSubmit={handleSubmit}>
        <IconButton className="feedback-alert__close-btn" onClick={handleClose}>
          <Close />
        </IconButton>
        <Typography
          component="h4"
          variant="h6"
          color="primary"
          align="center"
          className="feedback-alert__title"
        >
          {t("feedback_modal.title")}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          color="textPrimary"
          className="feedback-alert__text"
        >
          {t("feedback_modal.subtitle")}
        </Typography>
        <input
          type="text"
          name="username"
          placeholder={t("feedback_modal.name_placeholder") as string}
          value={userName}
          onChange={handleChange}
          className={errors.nameError ? "input-error" : ""}
        />
        {errors.nameError && (
          <div className="error">
            <img src={errorIcon} alt=""/>
            <small>{errors.nameError}</small>
          </div>
        )}
        <MaskedInput
          name="userphone"
          value={userPhone ? userPhone : "+380"}
          mask={maskUserPhone}
          onChange={handleChange}
          placeholder="Тел.: +380 50 996 53 25"
          guide={false}
          className={errors.phoneError ? "input-error" : ""}
        />
        {errors.phoneError && (
          <div className="error">
            <img src={errorIcon} alt=""/>
            <small>{errors.phoneError}</small>
          </div>
        )}
        <CustomButton
          color="secondary"
          variant="contained"
          type="submit"
          text={
            isLoading ? (
              <ButtonLoader />
            ) : (
              (t("feedback_modal.button") as string)
            )
          }
          disabled={isLoading}
        />
        {errors.adminError && (
          <div className="error">
            <small style={{ textAlign: "center" }}>{errors.adminError}</small>
          </div>
        )}
      </form>
    </Modal>
  );
};
