import React, { useState, useEffect } from "react";

import { useMediaQuery, useTheme } from "@material-ui/core";

import { validateInputs } from "../../utils/validateInputs";
import { notify } from "../../utils/notify";
import { requestForFeedback } from "../../utils/requestForFeedback";

interface IErrors {
  nameError: string;
  phoneError: string;
  adminError: string;
}

export const useFeedback = () => {
  const [userName, setUserName] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    nameError: "",
    phoneError: "",
    adminError: "",
  });

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (errors.adminError) {
      timeoutId = setTimeout(
        () => setErrors((prevErrors) => ({ ...prevErrors, adminError: "" })),
        10000
      );
    }

    return () => clearTimeout(timeoutId);
  }, [errors.adminError]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "username") {
      setUserName(e.target.value);
      if (isFormSubmitted) {
        setErrors((prevProps) => ({
          ...prevProps,
          nameError: validateInputs(e.target.value, "name"),
        }));
      }
    } else {
      setUserPhone(e.target.value);
      if (isFormSubmitted) {
        setErrors((prevProps) => ({
          ...prevProps,
          phoneError: validateInputs(e.target.value, "phone"),
        }));
      }
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const usernameError = validateInputs(userName, "name");
    const userphoneError = validateInputs(userName, "phone");

    setErrors((prevErrors) => ({
      ...prevErrors,
      nameError: usernameError,
      phoneError: userphoneError,
    }));

    if (!usernameError && !userphoneError) {
      setLoading(true);

      const response = await requestForFeedback(userName, userPhone);

      if (response.status === 200) {
        notify();
        setUserName("");
        setUserPhone("");
      } else if (response.status === 204 || response.status === 410) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          adminError:
            "Администратора нет на месте. Попробуйте, пожалуйста, позже",
        }));
      } else {
        console.log("sorry");
      }
    }

    if (!isFormSubmitted) {
      setFormSubmitted(true);
    }

    setLoading(false);
  };

  return {
    userName,
    userPhone,
    isLoading,
    errors,
    handleSubmit,
    handleChange,
    isMd,
  };
};
