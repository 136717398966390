import request, { gql } from "graphql-request";
const apiUrl =
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clv4ag2ri03li08w7i1kjz00o/master";

export const getPrices = async () => {
  const query = gql`
    query MyQuery {
      priceApi {
        optionRu
        option_uk
        price
        priceOption
        titleRu
        titleUk
        id
      }
    }
  `;

  const result = request(apiUrl, query);
  return result;
};

export const getServices = async () => {
  const query = gql`
    query MyQuery {
      servicesApi {
        title_uk
        titleRu
        infoUk
        infoRu
        image {
          url
        }
        id
      }
    }
  `;

  const result = request(apiUrl, query);
  return result;
};
