import React from 'react';
import { KeyboardArrowUp } from '@material-ui/icons';
import './to-top-button.scss';

interface IProps {
	handleToTopButtonClick: () => void;
}

export const ToTopButton: React.FC<IProps> = ({ handleToTopButtonClick }) => (
	<div className="wrapper">
		<div className="to-top-btn" onClick={handleToTopButtonClick}>
			<KeyboardArrowUp />
		</div>
	</div>
)
