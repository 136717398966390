import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackLng: "ru",
    resources: {
      ru: {
        translation: {
          privacy: {
            intro: "Согласно с условиями настоящего Договора Исполнитель с одной стороны, и любое лицо, принявшее условия настоящего Договора публичной оферты - Заказчик, с другой стороны, далее совместно именуемые - Стороны, заключили настоящий Договор публичной оферты (далее - Договор), адресованный неограниченному кругу лиц, являющийся официальным публичным предложением Исполнителя заключить с Заказчиками договор оказания услуг, информация о которых размещена на Веб-сайте Исполнителя https://bodymax.online/.",
            first: {
              title: "1. Общие положения",
              subtext: {
                first: "1.1. Договорные отношения между Исполнителем и Заказчиком оформляются в виде Договора публичной оферты. Оплата услуг означает, что Заказчик  заключил Договор публичной оферты, условия которого указаны ниже.",
                second: "1.2. Договор публичной оферты является публичным, то есть в соответствии со статьями 633, 641 Гражданского кодекса Украины его условия одинаковы для всех Заказчиков независимо от статуса (физическое лицо, юридическое лицо, физическое лицо-предприниматель). Заказчик принимает условия и порядок оформления заказа и оплаты.",
                third: "1.3. Настоящий Договор вступает в силу с момента предоплаты услуг (даты зачисления на расчетный счет Исполнителя) и действует до момента получения услуг Заказчиком.",
                fourth: "1.4. Договорные отношения между сторонами регулируются законодательством Украины."
              }
            },
            second: {
              title: "2. Термины",
              subtext: {
                first: "«Договор публичной оферты» - публичный договор, образец которого размещен на Веб-сайте https://bodymax.online/ предназначенный  неопределенному кругу лиц (далее – Договор).",
                second: "«Акцепт» - принятие Заказчиком предложения Заказчика об оказании услуг.",
                third: "«Услуга» – любая услуга Исполнителя, вид и стоимость которой определена на на Веб-сайте https://bodymax.online/, по предоставлению ограниченного доступа Заказчика к Сайту в соответствии с условиями Оферты для оказания информационно-консультационных услуг.",
                fourth: "«Заказчик» - любое дееспособное физическое, юридическое лицо, физическое лицо-предприниматель, заключившее Договор.",
                fifth: "«Исполнитель» - ФЛП Бондарь Максим Витальевич, ЕГРПОУ: 3390314135,	Счет IBAN: UA063524790000026003052233557, АО КБ «ПРИВАТБАНК» 01033 Украина, Голосеевский р-н, ул Антоновича, д. 29.	Веб-сайт https://bodymax.online/.",
                sixth: " «Законодательство» - установленные украинским законодательством нормы для регулирования договорных отношений."
              }
            },
            third: {
              title: "3. Предмет договора",
              subtext: {
                first: "3.1. Исполнитель обязуется на условиях и в порядке, определенных настоящим Договором предоставить услуги, а Заказчик обязуется на условиях и в порядке, определенных настоящим Договором , оплатить услуги.",
                second: "3.2. Исполнитель подтверждает, что имеет все необходимые разрешения на осуществление хозяйственной деятельности, которые регулируют сферу правоотношений, возникающих и действующих в процессе исполнения настоящего Договора, а также гарантирует, что имеет право на оказание услуг без каких-либо ограничений, в соответствии с требованиями действующего законодательства Украины, и обязуется нести ответственность в случае нарушения прав Заказчика в процессе исполнения настоящего Договора.",
              }
            },
            fourth: {
              title: "4. Права и обязанности исполнителя",
              subtext: {
                first: "4.1. Исполнитель обязан: \n • выполнять условия настоящего Договора; \n • выполнять заказы Заказчика после поступления оплаты от Заказчика;",
                second: "4.2. Исполнитель имеет право: \n • требовать от Заказчика выполнения условий настоящего Договора;",
              }
            },
            fifth: {
              title: "5. Права и обязанности заказчика",
              subtext: {
                first: "5.1. Заказчик обязан: \n • выполнять условия настоящего Договора;",
                second: "5.2. Заказчик имеет право: \n • ознакомиться с информацией об услугах, размещенной на Веб-сайте https://bodymax.online/ \n • оплатить и получить услуги на условиях настоящего Договора; \n • требовать от Исполнителя выполнения условий настоящего Договора;",
              }
            },
            sixth: {
              title: "6. Цена договора и порядок оплаты",
              subtext: {
                first: "6.1. Цена каждой отдельной услуги определяется Исполнителем и указывается на соответствующей странице Веб-сайта https://bodymax.online/. Цена Договора определяется путем сложения цен всех выбранных услуг.",
                second: "6.2. Стоимость Заказа может изменяться в зависимости от цены и количества услуг.",
                third: "6.3. Заказчик может оплатить заказ следующими способами:",
                fourth: "6.3.1. с помощью перевода денежных средств на счёт Исполнителя, заполнив платёжную форму на сайте;",
                fifth: "6.3.2. с помощью перевода денежных средств на счёт Исполнителя через приложение «Приват 24»;",
                sixth: "6.3.3. с помощью перевода денежных средств на счёт Исполнителя, отсканировав QR-код платёжной формы;",
                seventh: "6.3.4. с помощью перевода денежных средств на счёт Исполнителя, используя сервис «Google Pay»."
              }
            },
            seventh: {
              title: "7. Условия возврата товара и денежных средств",
              subtext: {
                first: "7.1. Возврат денежных средств предусматривается только в случае непредоставления Исполнителем оплаченных Заказчиком  услуг.",
                second: "7.2. Возврат денежных средств осуществляется посредством возврата стоимости оплаченных услуг почтовым, банковским переводом, а также путем перечисления на банковскую карту (карта должна быть эмитирована на территории Украины; валюта карты - гривна).",
              }
            },
            eighth: {
              title: "8. Ответственноть сторон и разрешение споров",
              subtext: {
                first: "8.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение условий настоящего Договора в порядке, предусмотренном настоящим Договором и действующим законодательством.",
                second: "8.2. В случае возникновения споров, связанных с выполнением Сторонами настоящего Договора,  Стороны обязуются решать их путем переговоров с соблюдением претензионного порядка. Срок рассмотрения претензии - 7 (семь) календарных дней с даты ее получения.",
                third: "8.3. Все споры, разногласия или требования, возникающие из настоящего Договора или в связи с ним, в том числе касающиеся его исполнения, нарушения, прекращения или недействительности, подлежат разрешению в соответствующем суде согласно с законодательством.",
              }
            },
            ninth: {
              title: "9. Форс-мажорные обстоятельства",
              subtext: {
                first: `9.1. Стороны не несут ответственности за невыполнение любого из своих обязательств, за исключением обязательств по оплате, если докажут, что такое неисполнение было вызвано форс-мажорными обстоятельствами, то есть событиями или обстоятельствами, которые действительно находятся вне контроля такой стороны, наступившие после заключения настоящего Договора, носящие непредсказуемый и неотвратимый характер. \n
			К форс-мажорным обстоятельствам относятся, в частности, природные катаклизмы, забастовки, пожары, наводнения, взрывы,  войны (как объявленные, так и необъявленные), мятежи, ограничения, накладываемые государственными органами (включая распределения, приоритеты, официальные требования, квоты и ценовой контроль), если эти обстоятельства непосредственно повлияли на исполнение настоящего Договора.
			`,
                second: "9.2. Сторона, для которой стало невозможным выполнение обязательств по настоящему Договору из-за наступления форс-мажорных обстоятельств, должна немедленно информировать другую Сторону в письменном виде о возникновении вышеуказанных обстоятельств, а также в течение 30 (тридцати) календарных дней предоставить другой Стороне подтверждение форс-мажорных обстоятельств. Таким подтверждением будет справка, сертификат или другой соответствующий документ, выданный уполномоченным государственным органом, расположенным по месту возникновения форс-мажорных обстоятельств.",
                third: "9.3. Время, которое требуется Сторонам для выполнения своих обязательств по настоящему Договору, будет продлено на любой срок, в течение которого было отложено исполнение из-за перечисленных обстоятельств.",
                fourth: "9.4. Если из-за действия обстоятельств непреодолимой силы неисполнение обязательств по настоящему Договору продолжается более трех месяцев, каждая из Сторон вправе расторгнуть настоящий Договор в одностороннем порядке, письменно уведомив об этом другую сторону.",
                fifth: "Не смотря на наступление форс-мажора, перед прекращением настоящего Договора вследствие форс-мажорных обстоятельств Стороны осуществляют окончательные взаиморасчеты."
              }
            },
            tenth: {
              title: "10. Прочие условия договора",
              subtext: {
                first: `10.1. Информация, предоставляемая Заказчиком, является конфиденциальной. Информация о Заказчике используется исключительно в целях выполнения его Заказа.`,
                second: "10.2. Собственным акцептированием Договора или регистрацией на Веб-сайте https://bodymax.online/ Заказчик добровольно дает согласие на сбор и обработку своих персональных данных с последующей целью: данные, которые становятся известны, будут использоваться в коммерческих целях, в том числе для обработки заказов на оказание услуг, получение информации о заказе, рассылки телекоммуникационными средствами связи (электронной почтой, мобильной связью) рекламных и специальных предложений, информации об акциях, розыгрыши или любой другой информации о деятельности Веб-сайта https://bodymax.online/ \n\n Для целей, предусмотренных настоящим пунктом, Заказчику могут направляться письма, сообщения и материалы на почтовый адрес, e-mail Заказчика, а также отправляться sms-сообщения, осуществляться звонки на телефонный номер Заказчика.",
                third: "10.3. Заказчик дает право осуществлять обработку его персональных данных, в том числе: помещать персональные данные в базы данных (без дополнительного уведомления об этом), осуществлять пожизненное хранение данных, их накопление, обновление, изменение (по мере необходимости). Исполнитель обязуется обеспечить защиту данных от несанкционированного доступа третьих лиц, не распространять и не передавать данные любой третьей стороне (кроме передачи данных связанным лицам, коммерческим партнерам, лицам, уполномоченным Исполнителем на осуществление непосредственной обработки данных для указанных целей, а также на обязательный запрос компетентного государственного органа).",
                fourth: "10.4. В случае нежелания получать рассылку, Заказчик имеет право обратиться к Исполнителю, написав заявление об отказе от получения рекламных материалов, направив его на почтовый или электронный адрес.",
                fifth: "10.5. Исполнитель не несет ответственности за содержание и достоверность информации, предоставляемой Заказчиком при оформлении заказа. Заказчик несет ответственность за достоверность указанной при оформлении заказа информации.",
              }
            },
          },
          address: "Киев, Антоновича 29",
          navbar: {
            massage: "Массажи",
            courses: "Онлайн курсы",
            price: "Прайс",
            reviews: "Отзывы",
            contacts: "Контакты",
            privacy: "Договор Оферты и Политика Конфиденциальности"
          },
          doctor: {
            button: "Записаться на прием",
            name: "bodymax.physiotherapy",
            surname: "Центр физиотерапии",
            caption:
              "BodyMax Physiotherapy - ваш ключ к здоровью и восстановлению. Наши опытные специалисты предлагают индивидуальные программы физиотерапии для вашего здоровья",
            desc_1: "Эксперт в области триггерных точек.",
            desc_2: "Специалист по головным болям.",
            desc_3: "Опыт работы 12 лет.",
          },
          massage_section_title: "Услуги",
          doctors_section_title: "Специалисты ",
          doctor_0: {
            name: "Бондарь Максим Витальевич",
            position: "Физиотерапевт, реабилитолог, хиропрактик",
            skill_1: "Эксперт в области тригерных точек.",
            skill_2: "Специалист по головным болям.",
            skill_3: "Опыт работы 12 лет.",
          },
          doctor_1: {
            name: "Лихошерстый Олег Анатольевич",
            position: "Физиотерапевт, кинезиотерапевт",
            skill_1: "Специалист в области диагностики суставов и мышц.",
            skill_2: "Точечный глубокотканный массаж триггерных точек.",
            skill_3:
              "Устранение сосудистых патологий (головокружение, головные боли, синдром позвоночной артерии).",
            skill_4:
              "Восстановление искривленной осанки (гиперлордоз, сколиоз, сутулость).",
            skill_5:
              "Мануальные техники при защемлении позвоночника и суставов.",
          },
          doctor_2: {
            name: "Гетманов Денис Валентинович",
            position: "Реабилитолог, массажист",
            skill_1: "Специалист по триггерным точкам.",
            skill_2: "Эксперт в области миопрессуры.",
            skill_3:
              "Специализируется на силовом глуботканном массаже.",
          },
          courses_section_title: "Онлайн курсы",
          neck_course: {
            title: "Онлайн курс по восстановлению шейного отдела позвоночника",
            text: "В данный курс входит реабилитация шейного и грудного отдела позвоночника. Курс состоит из 12 частей длительностью 2 часа. Этап реабилитации займет 2 недели, с четким графиком и всеми рекомендациями. Кому подойдет этот курс?",
            item_1:
              "Для людей у кого есть шейный остеохондроз, осложненный протрузиями и межпозвонковыми грыжами.",
            item_2: "Людям - у которых хрустит, щелкает шея.",
            item_3: "При проблемах кровообращения и зажатых сосудах шеи.",
            item_4: "При головных болях.",
            item_5: "Людям, с миофасциальным триггерным синдромом.",
          },
          anathomy_course: {
            title:
              "Анатомия человека (мышцы и их движения) нервы, сосуды. В 3д",
            text: "В данном курсе:",
            item_1:
              "вы сможете выучить название мышц человека. Увидеть их место крепления и расположение конкретной мышцы.",
            item_2: "разберётесь в иннервации и  кровоснабжении мышц.",
            item_3:
              "научитесь понимать какие движения выполняет конкретная мышца, как работает с другими мышцами.",
            additional_text:
              "Это онлайн видеообучение. После оплаты вам придёт ссылка с безлимитным доступом на курс. Видео вы сможете скачать или просмотреть в любое время. Курс по анатомии состоит из 22 частей. Каждая часть посвящена отдельному разбору участка тела: голова, шея, спина, плечи, руки, ноги, живот. Длительность одного видео, в среднем 10 - 12 минут. Видеокурс 'Анатомия человека' на столько понятен и легко усвояем, что в нем сможет разобраться обыватель и человек без мед образования.",
          },
          trigger_points_course: {
            title: "Массаж по триггерным точкам (Про версия)",
            text: "Научиться триггерному массажу может каждый, ведь этот видеокурс содержит профессиональные техники миопрессуры, которые удачно деактивируют триггеры и восстанавливают тонус мышц. Видеокурс состоит из 3 частей:",
            item_1:
              "1 часть – Массажные зоны и анатомические участки. В ней показал расположение позвонков, нервов, сосудов, мышц, наличие триггерных точек.",
            item_2:
              "2 часть – Техники и приемы массажа. Смотрите и повторяйте за мной, как я делаю. Направления и составляющие всех массажных движений.",
            item_3:
              "3 часть – Общение с клиентом. Важная часть, доверия пациента и массажиста. Правила общения.",
          },
          percussion_course: {
            title: "Методика перкуссионного массажа",
            text: "Массажные технологии развиваются, а вы как специалист, хотите расти и развиваться вместе с ними. Для этого я создал видеокурс по применению массажного пистолета. В данном видеокурсе, длительностью 1.5 часа, вы сможете разобрать и выучить такие моменты:",
            item_1:
              "Как подобрать массажный пистолет, на что ориентироваться перед покупкой. Сравнить с другими аппаратами.",
            item_2:
              "Как пользоваться насадками к пистолету, где их применять, где лучше избегать,  чтоб не было обострений.",
            item_3:
              "Сможете выучить анатомию. Увидете расположения  мышц. Сможете грамотно выполнить массаж.",
            item_4:
              "Научитесь правильно работать с триггерными точками при миофасциальном синдроме.",
            item_5:
              "Сможете применять данные техники дома, восстанавливать родных, так и в профессиональной массажной практике.",
            additional_text:
              "Весь профессиональный опыт за 5 лет работы массажным пистолетом, я собрал в одном курсе. А вы сможете усвоить мой опыт, всего за 1.5 часа.",
          },
          panic_attacks_course: {
            title: "Методики и техники устранения панических атак",
            text: "Если у вас частые головокружения, болит голова, бессонница. Во время засыпания вы слышите свое сердцебиение и испытываете чувство тревоги. Если все эти симптомы у вас есть? - тогда этот видеокурс точно для вас. В этом видеокурсе:",
            item_1:
              "Вы научитесь снимать приступы панической атаки. Вам больше не придётся ждать скорую, вы сами все сможете сделать.",
            item_2:
              "Научитесь контролировать свое состояние и не доводить себя до приступов.",
            item_3:
              "За месяц количество приступов уменьшаться во много раз и даже вовсе могут исчезнуь.",
            item_4:
              "При соблюдении всех рекомендаций вы улучшите состояние своего организма, нормализуете сон и жизненную энергию.",
            item_5: "Курс рассчитан на 1 месяц.",
          },
          treatment_results_section_title: "Наши результаты",
          treatment_results: {
            before: "До",
            after: "После",
          },
          prices_section_title: "Прайс",
          prices_options: {
            item_1: "",
            item_2: "за отдел",
            item_3: "зона",
            item_4: "курс индивидуально",
          },
          prices_section_subtitle_2:
            "все дополнительные процедуры выполняются на усмотрение специалистов для более быстрого и безопасного результата !",
          prices_text: "зона от",
          feedback_modal: {
            title: "Оставить заявку",
            subtitle:
              "Оставьте номер телефона и мы обязательно свяжемся с вами.",
            name_placeholder: "Введите имя",
            button: "Отправить",
          },
          errors: {
            empty_field: "Заполните это поле",
            invalid_name: "Имя не должно содержать цифр и спецсимволов",
          },
          reviews_section_title: "Отзывы",
          reviews: {
            novikova: {
              name: "Юлия Новикова",
              status: "Пенсионер",
              text: "Огромная благодарность за ценную информацию. Максим - профессионал своего дела. Только самые актуальные темы. Спасибо, что спасли мое здоровье!",
            },
            fedorov: {
              name: "Олег Федоров",
              status: "Предприниматель",
              text: "Максим, спасибо за Ваш труд, старания и граммотную, доступную подачу материала! Вы помогли мне вылечить плечо, которое болело уже на протяжении 1 года.",
            },
          },
          socials_section_title: "Подписывайтесь и будьте в курсе",
          socials: {
            subscribers: "Подписчиков",
            button: "Подписаться",
          },
          contacts_section_title: "Контакты",
          contacts: {
            address: {
              title: "Адрес",
              text: "г. Киев, метро Олимпийская, Антоновича 29",
            },
            schedule: {
              title: "Режим работы",
              item_1: "пн-пт с 10:00 до 29:00",
              item_2: "сб-вс - выходной",
            },
          },
          buy_button: "Купить",
          reviews_button: "Отзывы",
          preview_video_button: "Смотреть видео о курсе",
          more_courses_button: "Больше курсов",
          contact_us_button: "Написать нам",
          apps: {
            body_max_app_description:
              "BodyMax.Rehabilitation - это приложение, которое содержит комплекс упражнений по восстановлению опорно-двигательного аппарата человека. Реабилитация при остеохондрозе, межпозвоночных грыжах, сколиозе, сутулости, при онемении пальцев рук, нарушении мозгового кровообращения и защемлениях в спине, проблемах суставов и многих других паталогиях. Данные комплексы разработаны с помощью уникальных движений, направленных на реабилитацию человека. Все они носят оздоровительный эффект, улучшая кровообращения в мышцах, устраняя защемление нерва, восстанавливая мозговое кровообращение, стабилизируя давление, нормализуют подвижность суставов. Реабилитационные занятия можно выполнять в домашних условиях, имея минимальный набор реабилитационного оборудования (резинка/жгут, теннисный мяч, палка).",
            trigger_points_app_description:
              "Данное приложение содержит размещение триггерных точек на проекции тела человека, и упражнения по обезболиванию мышц. Вы можете увидеть каждую мышцу и ее боль. У вас будут все инструменты для устранения триггерных точек.",
          },
          apps_download_button: "Скачать",
        },
      },
      uk: {
        translation: {
          privacy: {
            intro: "Згідно з умовами цього Договору Виконавець з одного боку, і будь-яка особа, яка прийняла умови цього Договору публічної оферти - Замовник, з іншого боку, далі спільно іменовані - Сторони, уклали цей Договір публічної оферти (далі - Договір), адресований необмеженому колу осіб, що є офіційною публічною пропозицією Виконавця укласти із Замовниками договір надання послуг, інформація про які розміщена на Веб-сайті Виконавця https://bodymax.online/.",
            first: {
              title: "1. Загальні положення",
              subtext: {
                first: "1.1 Договірні відносини між Виконавцем і Замовником оформляються у вигляді Договору публічної оферти. Оплата послуг означає, що Замовник уклав Договір публічної оферти, умови якого зазначені нижче.",
                second: "1.2 Договір публічної оферти є публічним, тобто відповідно до статей 633, 641 Цивільного кодексу України його умови однакові для всіх Замовників незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець). Замовник приймає умови і порядок оформлення замовлення та оплати.",
                third: "1.3 Цей Договір набуває чинності з моменту передоплати послуг (дати зарахування на розрахунковий рахунок Виконавця) і діє до моменту отримання послуг Замовником.",
                fourth: "1.4 Договірні відносини між сторонами регулюються законодавством України."
              }
            },
            second: {
              title: "2. Терміни",
              subtext: {
                first: "«Договір публічної оферти» - публічний договір, зразок якого розміщено на Веб-сайті https://bodymax.online/, призначений невизначеному колу осіб (далі - Договір).",
                second: "«Акцепт» - прийняття Замовником пропозиції Замовника про надання послуг.",
                third: "«Послуга» - будь-яка послуга Виконавця, вид і вартість якої визначена на Веб-сайті https://bodymax.online/, з надання обмеженого доступу Замовника до Сайту відповідно до умов Оферти для надання інформаційно-консультаційних послуг.",
                fourth: "«Замовник» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, яка уклала Договір.",
                fifth: "«Виконавець» - ФОП Бондар Максим Віталійович, ЄДРПОУ: 3390314135, Рахунок IBAN: UA063524790000000026003052233557, АТ КБ «ПРИВАТБАНК» 01033, Україна, Голосіївський р-н, вул. Антоновича, буд. 29.\tВеб-сайт https://bodymax.online/.",
                sixth: "«Законодавство» - встановлені українським законодавством норми для регулювання договірних відносин."
              }
            },
            third: {
              title: "3. Предмет договору",
              subtext: {
                first: "3.1 Виконавець зобов'язується на умовах та в порядку, визначених цим Договором, надати послуги, а Замовник зобов'язується на умовах та в порядку, визначених цим Договором, оплатити послуги.",
                second: "3.2 Виконавець підтверджує, що має всі необхідні дозволи на здійснення господарської діяльності, які регулюють сферу правовідносин, що виникають і діють у процесі виконання цього Договору, а також гарантує, що має право на надання послуг без будь-яких обмежень, відповідно до вимог чинного законодавства України, і зобов'язується нести відповідальність у разі порушення прав Замовника в процесі виконання цього Договору.",
              }
            },
            fourth: {
              title: "4. Права та обов'язки виконавця",
              subtext: {
                first: "4.1. Виконавець зобов'язаний: \n • виконувати умови цього Договору; \n • виконувати замовлення Замовника після надходження оплати від Замовника;",
                second: "4.2 Виконавець має право: \n • вимагати від Замовника виконання умов цього Договору;",
              }
            },
            fifth: {
              title: "5. Права та обов'язки замовника",
              subtext: {
                first: "5.1 Замовник зобов'язаний: \n • виконувати умови цього Договору;",
                second: "5.2. Замовник має право: \n • ознайомитися з інформацією про послуги, розміщеною на Веб-сайті https://bodymax.online/ \n • оплатити та отримати послуги на умовах цього Договору; \n • вимагати від Виконавця виконання умов цього Договору;",
              }
            },
            sixth: {
              title: "6. Ціна договору та порядок оплати",
              subtext: {
                first: "6.1 Ціна кожної окремої послуги визначається Виконавцем і зазначається на відповідній сторінці Веб-сайту https://bodymax.online/. Ціна Договору визначається шляхом додавання цін усіх обраних послуг.",
                second: "6.2 Вартість Замовлення може змінюватися залежно від ціни та кількості послуг.",
                third: "6.3 Замовник може оплатити замовлення такими способами:",
                fourth: "6.3.1. за допомогою переказу грошових коштів на рахунок Виконавця, заповнивши платіжну форму на сайті;",
                fifth: "6.3.2. за допомогою переказу грошових коштів на рахунок Виконавця через додаток «Приват 24»;",
                sixth: "6.3.3. за допомогою переказу грошових коштів на рахунок Виконавця, відсканувавши QR-код платіжної форми;",
                seventh: "6.3.4. за допомогою переказу грошових коштів на рахунок Виконавця, використовуючи сервіс «Google Pay»."
              }
            },
            seventh: {
              title: "7. Умови повернення товару і грошових коштів",
              subtext: {
                first: "7.1 Повернення грошових коштів передбачається тільки в разі ненадання Виконавцем оплачених Замовником послуг.",
                second: "7.2 Повернення грошових коштів здійснюється за допомогою повернення вартості оплачених послуг поштовим, банківським переказом, а також шляхом перерахування на банківську картку (картка має бути емітована на території України; валюта картки - гривня).",
              }
            },
            eighth: {
              title: "8. Відповідальність сторін і вирішення спорів",
              subtext: {
                first: "8.1 Сторони несуть відповідальність за невиконання або неналежне виконання умов цього Договору в порядку, передбаченому цим Договором і чинним законодавством.",
                second: "8.2 У разі виникнення суперечок, пов'язаних з виконанням Сторонами цього Договору, Сторони зобов'язуються вирішувати їх шляхом переговорів з дотриманням претензійного порядку. Строк розгляду претензії - 7 (сім) календарних днів з дати її отримання.",
                third: "8.3 Усі спори, розбіжності або вимоги, що виникають із цього Договору або у зв'язку з ним, зокрема ті, що стосуються його виконання, порушення, припинення або недійсності, підлягають розв'язанню у відповідному суді згідно із законодавством.",
              }
            },
            ninth: {
              title: "9. Форс-мажорні обставини",
              subtext: {
                first: `9.1 Сторони не несуть відповідальності за невиконання будь-якого зі своїх зобов'язань, за винятком зобов'язань з оплати, якщо доведуть, що таке невиконання було спричинене форс-мажорними обставинами, тобто подіями чи обставинами, що справді перебувають поза контролем такої сторони, настали після укладення цього Договору, мають непередбачуваний і невідворотний характер. \n
			До форс-мажорних обставин належать, зокрема, природні катаклізми, страйки, пожежі, повені, вибухи, війни (як оголошені, так і неоголошені), заколоти, обмеження, які накладають державні органи (включно з розподілом, пріоритетами, офіційними вимогами, квотами та ціновим контролем), якщо ці обставини безпосередньо вплинули на виконання цього Договору.
			`,
                second: "9.2 Сторона, для якої стало неможливим виконання зобов'язань за цим Договором через настання форс-мажорних обставин, повинна негайно інформувати іншу Сторону письмово про виникнення вищевказаних обставин, а також протягом 30 (тридцяти) календарних днів надати іншій Стороні підтвердження форс-мажорних обставин. Таким підтвердженням буде довідка, сертифікат або інший відповідний документ, виданий уповноваженим державним органом, розташованим за місцем виникнення форс-мажорних обставин.",
                third: "9.3 Час, який потрібен Сторонам для виконання своїх зобов'язань за цим Договором, буде продовжено на будь-який строк, протягом якого було відкладено виконання через перелічені обставини.",
                fourth: "9.4 Якщо через дію обставин непереборної сили невиконання зобов'язань за цим Договором триває більше трьох місяців, кожна зі Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону.",
                fifth: "Не зважаючи на настання форс-мажору, перед припиненням цього Договору внаслідок форс-мажорних обставин Сторони здійснюють остаточні взаєморозрахунки."
              }
            },
            tenth: {
              title: "10. Інші умови договору",
              subtext: {
                first: `10.1. Інформація, що надається Замовником, є конфіденційною. Інформація про Замовника використовується виключно з метою виконання його Замовлення.`,
                second: "10.2. Власним акцептуванням Договору або реєстрацією на Веб-сайті https://bodymax.online/ Замовник добровільно надає згоду на збирання та обробку своїх персональних даних з наступною метою: дані, що стають відомими, будуть використовуватися з комерційною метою, зокрема для опрацювання замовлень на надання послуг, отримання інформації про замовлення, надсилання телекомунікаційними засобами зв'язку (електронною поштою, мобільним зв'язком) рекламних і спеціальних пропозицій, інформації про акції, розіграші або будь-яку іншу інформацію про діяльність Веб-сайту https://bodymax.online/.  \n\n  Для цілей, передбачених цим пунктом, Замовнику можуть надсилатися листи, повідомлення та матеріали на поштову адресу, e-mail Замовника, а також надсилатися sms-повідомлення, здійснюватися дзвінки на телефонний номер Замовника.",
                third: "10.3. Замовник надає право здійснювати обробку його персональних даних, зокрема: поміщати персональні дані в бази даних (без додаткового повідомлення про це), здійснювати довічне зберігання даних, їхнє накопичення, оновлення, зміну (у міру необхідності). Виконавець зобов'язується забезпечити захист даних від несанкціонованого доступу третіх осіб, не розповсюджувати та не передавати дані будь-якій третій стороні (окрім передання даних пов'язаним особам, комерційним партнерам, особам, уповноваженим Виконавцем на здійснення безпосереднього опрацювання даних для зазначених цілей, а також на обов'язковий запит компетентного державного органу).",
                fourth: "10.4. У разі небажання отримувати розсилку, Замовник має право звернутися до Виконавця, написавши заяву про відмову від отримання рекламних матеріалів, направивши її на поштову або електронну адресу.",
                fifth: "10.5. Виконавець не несе відповідальності за зміст і достовірність інформації, що надається Замовником під час оформлення замовлення. Замовник несе відповідальність за достовірність зазначеної при оформленні замовлення інформації.",
              }
            },
          },
          address: "Київ, Антоновича 29",
          navbar: {
            massage: "Масаж",
            courses: "Онлайн курси",
            price: "Прайс",
            reviews: "Відгуки",
            contacts: "Контакти",
            privacy: "Договір Оферти та Політика Конфіденційності"
          },
          doctor: {
            button: "Записатися на прийом",
            name: "bodymax.physiotherapy",
            surname: "Центр фізіотерапії",
            caption:
              "BodyMax Фізіотерапія - ваш ключ до здоров'я та відновлення. Наші досвідчені фахівці пропонують індивідуальні програми фізіотерапії для вашого здоровʼя",
            desc_1: "Експерт у галузі тригерних точок",
            desc_2: "Фахівець з головного болю",
            desc_3: "Досвід роботи 12 років",
          },
          massage_section_title: "Послуги",
          doctors_section_title: "Фахівці",
          doctor_0: {
            name: "Бондарь Максим Віталійович",
            position: "Фізіотерапевт, реабілітолог, хіропрактик",
            skill_1: "Експерт з тригерних точок.",
            skill_2: "Спеціаліст з головних болей.",
            skill_3: "Досвід роботи 12 років.",
          },
          doctor_1: {
            name: "Лихошерстий Олег Анатолійович",
            position: "Фізіотерапевт, кінезіотерапевт",
            skill_1: "Фахівець у галузі діагностики суглобів та м'язів.",
            skill_2: "Точковий глибокотканинний масаж тригерних точок.",
            skill_3:
              "Усунення судинних патологій (запаморочення, головний біль, синдром хребцевої артерії).",
            skill_4:
              "Відновлення викривленої постави (гіперлордоз, сколіоз, сутулість).",
            skill_5:
              "Відновлення викривленої постави (гіперлордоз, сколіоз, сутулість).",
          },
          doctor_2: {
            name: "Гетманов Денис Валентинович",
            position: "Реабілітолог, масажист",
            skill_1: "Фахівець з тригерних точок.",
            skill_2: "Експерт у галузі міопресури.",
            skill_3:
              "Спеціалізується на силовому глибокотканинному масажі.",
          },
          courses_section_title: "Курси",
          neck_course: {
            title: "Онлайн курс відновлення шийного відділу хребта",
            text: "У даний курс входить реабілітація шийного та грудного відділу хребта. Курс складається із 12 частин тривалістю 2 години. Етап реабілітації займе 2 тижні, з чітким графіком та всіма рекомендаціями. Кому підійде цей курс?",
            item_1:
              "Для людей у   кого є шийний остеохондроз, ускладнений протрузіями та міжхребцевими грижами.",
            item_2: "Людям - у яких хрумтить, клацає шия.",
            item_3: "При проблемах кровообігу та затиснутих судинах шиї.",
            item_4: "При головних болях.",
            item_5: "Людям з міофасціальним тригерним синдромом.",
          },
          anathomy_course: {
            title: "Анатомія людини (м'язи та їх рух), нерви, судини. У 3д.",
            text: "У цьому курсі:",
            item_1:
              "ви зможете вивчити назву м'язів людини. Побачити їх місце кріплення та розташування конкретного м'яза.",
            item_2: "розберетеся в іннервації та кровопостачання м'язів.",
            item_3:
              "навчитеся розуміти які рухи виконує конкретний м'яз, як працює з іншими м'язами.",
            additional_text:
              "Це онлайн відеонавчання. Після сплати вам прийде посилання з безлімітним доступом на курс. Відео ви зможете завантажити або переглянути у будь-який час. Курс анатомії складається з 22 частин. Кожна частина присвячена окремому розбору ділянки тіла: голова, шия, спина, плечі, руки, ноги, живіт. Тривалість одного відео, в середньому 10 – 12 хвилин. Відеокурс 'Анатомія людини' на стільки зрозумілий і легко засвоюємо, що в ньому зможе розібратися обиватель і людина без медичної освіти.",
          },
          percussion_course: {
            title: "Методика перкусійного масажу",
            text: "Масажні технології розвиваються, а ви як фахівець, хочете рости та розвиватися разом з ними. Для цього я створив відеокурс із застосування масажного пістолета. У даному відеокурсі, тривалістю 1.5 години, ви зможете розібрати та вивчити такі моменти:",
            item_1:
              "Як підібрати масажний пістолет, на що орієнтуватися перед покупкою. Порівняти з іншими апаратами.",
            item_2:
              "Як скористатися насадками до пістолета, де їх використовувати, де краще уникати, щоб не було загострень.",
            item_3:
              "Чи зможете вивчити анатомію. Побачите розташування м'язів. Чи зможете грамотно виконати масаж.",
            item_4:
              "Навчитеся правильно працювати з тригерними точками при міофасціальному синдромі.",
            item_5:
              "Чи зможете застосовувати дані техніки вдома, відновлювати рідних, так і в професійній масажній практиці.",
            additional_text:
              "Весь професійний досвід за 5 років роботи масажним пістолетом я зібрав в одному курсі. А ви зможете засвоїти мій досвід лише за 1.5 години.",
          },
          trigger_points_course: {
            title: "Масаж по тригерним точкам (Про версія)",
            text: "Навчитися тригерному масажу може кожен, адже цей відеокурс містить професійні техніки з міопресури, які вдало деактивують тригери та відновлюють тонус мʼязів. Відеокурс складається з 3 частин:",
            item_1:
              "1 частина - Масажні зони та анатомічні ділянки. У ній показав розташування хребців, нервів, судин, м'язів, наявність тригерних точок.",
            item_2:
              "2 частина - Техніки та прийоми масажу. Дивіться та повторюєте за мною, як я роблю. Напрямки та складову всіх масажних рухів.",
            item_3:
              "3 частина - Спілкування з клієнтом. Важлива частина, довіри пацієнта та масажиста. Правила спілкування.",
          },
          panic_attacks_course: {
            title: "Методики та техніки усунення панічних атак",
            text: "Якщо у вас часті запаморочення, болить голова, безсоння. Під час засинання ви чуєте своє серцебиття і відчуваєте тривогу. Якщо усі ці симптоми у вас є? - Тоді цей відеокурс точно для вас. У цьому відеокурсі:",
            item_1:
              "Ви навчитеся знімати напади панічної атаки. Вам більше не доведеться чекати на швидку, ви самі все зможете зробити.",
            item_2:
              "Навчіться контролювати свій стан і не доводити себе до нападів.",
            item_3:
              "За місяць кількість нападів зменшуватиметься багато разів і навіть зовсім можуть зникнути.",
            item_4:
              "За дотримання всіх рекомендацій ви покращите стан свого організму, нормалізуєте сон і життєву енергію.",
            item_5: "Курс розрахований на 1 місяць",
          },
          treatment_results_section_title: "Наші результати",
          treatment_results: {
            before: "До",
            after: "Після",
          },
          prices_section_title: "Прайс",
          prices_options: {
            item_1: "",
            item_2: "за відділ",
            item_3: "зона",
            item_4: "курс індивідуально",
          },
          prices_section_subtitle_2:
            "всі додаткові процедури виконуються на розсуд спеціалістів для більш швидкого та безпечного результату!",
          prices_text: "зона від",
          feedback_modal: {
            title: "Залишити заявку",
            subtitle:
              "Залишіть номер телефону, і ми обов'язково зв'яжемося з вами",
            name_placeholder: "Ваше ім'я",
            button: "Надіслати",
          },
          errors: {
            empty_field: "Заповніть це поле",
            invalid_name: "Ім'я повинно містити тільки літери",
          },
          reviews_section_title: "Відгуки",
          reviews: {
            novikova: {
              name: "Юлія Новікова",
              status: "Пенсіонер",
              text: "Дуже вдячна за цінну інформацію. Максим - професіонал своєї справи. Лише найактуальніші теми. Дякую, що врятували моє здоров'я!",
            },
            fedorov: {
              name: "Олег Федоров",
              status: "Підприємець",
              text: "Максиме, дякую за Ваш труд, старания и граммотную, доступную подачу материала! Вы помогли мне вылечить плечо, которое болело уже на протяжении 1 года.",
            },
          },
          socials_section_title: "Підпишіться і будьте в курсі",
          socials: {
            subscribers: "Підписників",
            button: "Підписатися",
          },
          contacts_section_title: "Контакти",
          contacts: {
            address: {
              title: "Адреса",
              text: "м. Київ, метро Олімпійська, Антоновича 29",
            },
            schedule: {
              title: "Графік роботи",
              item_1: "пн-пт з 10:00 до 19:00",
              item_2: "сб-нд - вихідний",
            },
          },
          buy_button: "Купити",
          reviews_button: "Відгуки",
          preview_video_button: "Переглянути відео про курс",
          more_courses_button: "Більше курсів",
          contact_us_button: "Написати нам",
          apps: {
            body_max_app_description:
              "BodyMax.Rehabilitation - це застосунок, який містить комплекс вправ з відновлення опорно-рухового апарату людини. Реабілітація при остеохондрозі, міжхребцевих грижах, сколіозі, сутулості, при онімінні пальців рук, порушенні мозкового кровообігу та затисканнях у спині, проблемах суглобів та багатьох інших патологіях. Ці комплекси розроблені за допомогою унікальних рухів, спрямованих на реабілітацію людини. Усі вони мають оздоровчий ефект, покращуючи кровообіг у м'язах, усуваючи защемлення нерва, відновлюючи мозковий кровообіг, стабілізуючи тиск, нормалізують рухливість суглобів. Реабілітаційні заняття можна виконувати в домашніх умовах, маючи мінімальний набір реабілітаційного обладнання (резинка/джгут, тенісний м'яч, палиця).",
            trigger_points_app_description:
              "Даний додаток містить розміщення тригерних точок на проекції тіла людини, та вправи щодо знеболювання м'язів. Ви можете побачити кожен м'яз та його біль. У вас будуть усі інструменти для усунення тригерних точок.",
          },
          apps_download_button: "Завантажити",
        },
      },
    },
  });

export default i18n;
