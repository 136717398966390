import React from "react";
import MaskedInput from "react-text-mask";
import { Container, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { ButtonLoader } from "../../atoms/ButtonLoader/ButtonLoader";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";

import { maskUserPhone } from "../../static/index";
import { useFeedback } from "./useFeeedback";
import errorIcon from "../../assets/images/email-error.svg";

import "./feedback.scss";

export const Feedback: React.FC = () => {
  const {
    userName,
    userPhone,
    isLoading,
    errors,
    handleSubmit,
    handleChange,
    isMd,
  } = useFeedback();
  const { t } = useTranslation();

  return (
    <Container fixed>
      <Grid container className="feedback" id="feedback" alignItems="center">
        <Grid
          container
          item
          xs={12}
          md={5}
          justifyContent={isMd ? "center" : "flex-end"}
          className="feedback__img-wrapper"
        >
          <img
            srcSet="
                /feedback/feedback-md.webp 320w,
                /feedback/feedback-md.webp 581w,
                /feedback/feedback-md.webp 780w,
                /feedback/feedback-xl.webp 937w,
                /feedback/feedback-xl.webp 1024w"
            sizes="
                (max-width: 599px) 380px,
                (max-width: 959px) 510px,
                (max-width: 1279px) 380px,
                1024px"
            src="/feedback/feedback-l.webp"
            alt="Doctor"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <form className="feedback__form" onSubmit={handleSubmit}>
            <Typography
              component="h4"
              variant="h6"
              color="primary"
              align="center"
              className="feedback__title"
            >
              {t("feedback_modal.title")}
            </Typography>
            <Typography
              component="p"
              variant="body1"
              color="textPrimary"
              className="feedback__text"
            >
              {t("feedback_modal.subtitle")}
            </Typography>
            <input
              type="text"
              name="username"
              placeholder={t("feedback_modal.name_placeholder") as string}
              value={userName}
              onChange={handleChange}
              className={errors.nameError ? "input-error" : ""}
            />
            {errors.nameError && (
              <div className="error">
                {/* <img src={errorIcon} alt="" /> */}
                <small>{errors.nameError}</small>
              </div>
            )}
            <MaskedInput
              name="userphone"
              value={userPhone ? userPhone : "+380"}
              mask={maskUserPhone}
              onChange={handleChange}
              placeholder="Тел.: +380 50 996 53 25"
              guide={false}
              className={errors.phoneError ? "input-error" : ""}
            />
            {errors.phoneError && (
              <div className="error">
                <img src={errorIcon} alt=""/>
                <small>{errors.phoneError}</small>
              </div>
            )}
            <CustomButton
              color="secondary"
              variant="contained"
              type="submit"
              text={
                isLoading ? (
                  <ButtonLoader />
                ) : (
                  (t("feedback_modal.button") as string)
                )
              }
              disabled={isLoading}
            />
            {errors.adminError && (
              <div className="error">
                <small style={{ textAlign: "center" }}>
                  {errors.adminError}
                </small>
              </div>
            )}
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
