import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@material-ui/core";

import { TreatmentCard } from "./atoms/TreatmentCard/TreatmentCard";

import "./treatment-results.scss";
import { useTranslation } from "react-i18next";

const cardsContent = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
];

export const TreatmentResults: React.FC = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: false,
    waitForAnimate: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <Container component="section" className="treatment" id="treatment" fixed>
      <Box
        display="flex"
        flexDirection="column"
        className="treatment__wrapper"
        data-aos="zoom-in-down"
      >
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="treatment__title"
        >
          {t("treatment_results_section_title")}
        </Typography>
        <Slider {...settings} className="treatment__slider">
          {cardsContent.map(({ id, ...restProps }) => (
            <TreatmentCard key={id} />
          ))}
        </Slider>
      </Box>
    </Container>
  );
};
